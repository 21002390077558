import React from 'react';

function About(props) {
    return (
        <div>
            heheh
        </div>
    );
}

export default About;