import React, { useEffect, useState } from 'react';
import { Row, Form, Button, Col } from 'react-bootstrap'
import { account } from '../../config/appwrite';

function Profile(props) {
    const [user, setUser] = useState();

    useEffect(() => {
        account.get().then((user) => setUser(user), (error) => console.log(error));
        console.log('Profile user: ' + user.name);
    }, []);

    return (
        <Row className="justify-content-md-center">
            <Col md={6}>
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="text" placeholder="Enter email" value={user?.name} />
                        <Form.Text className="text-muted">
                            We'll never share your email with anyone else.
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Check me out" />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
            </Col>
        </Row>
    );
}

export default Profile;