import { Client, Account, Databases, Locale } from "appwrite";

const client = new Client();

client
    .setEndpoint('https://api.aiviet.click/v1')
    .setProject('63e45d7c1e12937802f2');

client.subscribe('files', response => {
    if (response.events.includes('buckets.*.files.*.create')) {
        // Log when a new file is uploaded
        console.log(response.payload);
    }
});

client.subscribe('files', response => {
    if (response.events.includes('buckets.*.files.*.create')) {
        // Log when a new file is uploaded
        console.log(response.payload);
    }
});


const account = new Account(client);
const database = new Databases(client);
const locale = new Locale(client);

export {
    client,
    account,
    database,
    locale,
}