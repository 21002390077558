import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import { About, Auth, Home, LoginFail, LoginRequire, Master, MasterAuth, Profile } from '../views';

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Master />}>
            <Route index element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/loginfail" element={<LoginFail />} />
            <Route path="/login" element={<LoginRequire />} />
            <Route path="/auth" element={<MasterAuth />} >
                <Route index element={<Auth />} />
                <Route path="profile" element={<Profile />} />
            </Route>
        </Route>
    )
)

export default router;