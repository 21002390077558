import React from 'react';
import { observer } from 'mobx-react';

import authStore from '../../store';

function Auth(props) {
    React.useEffect(() => {

    }, []);

    return (
        <div>
            Welcome to the Admin {authStore.auth.name} - {authStore.auth.email}
        </div>
    );
}

export default observer(Auth);