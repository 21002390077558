import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'

import { account } from '../config/appwrite'
import authStore from '../store';
import { observer } from 'mobx-react';

function HeaderCom(props) {
    const urlLogin = window.location.origin
    const navigate = useNavigate();

    const btnLogin = async () => {
        await account.createOAuth2Session('google', `${urlLogin}/auth`)
            .then(function (response) {
                console.log(response); // Success
            }, function (error) {
                console.log(error); // Failure
                navigate("/loginfail");
            });
    }

    const btnLogout = async () => {
        await account.deleteSession("current")
            .then(() => {
                authStore.setHasLogin(false);
                navigate("/login");
            }, function (error) {
                console.log(error);
            });
    }

    return (
        <>
            <Navbar bg="light" expand="lg">
                <Container>
                    <Navbar.Brand as={Link} to='/'>Ai Viet</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link as={Link} to='/'>Home</Nav.Link>
                            <Nav.Link as={Link} to='/about'>About</Nav.Link>
                        </Nav>
                        <Nav >

                            {(authStore.isAuth === false) ? (
                                <Button variant="outline-primary" onClick={btnLogin}>Login by Google</Button>
                            ) : (
                                <>
                                    <Nav.Link as={Link} to='/auth/profile'>{authStore.auth.name}</Nav.Link>
                                    <Button variant="outline-warning" onClick={btnLogout}>Logout</Button>
                                </>

                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

        </>
    );
}

export default observer(HeaderCom);