import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';

import { HeaderCom } from '../../companents';
import { locale } from '../../config/appwrite';
import { createIpAcc } from '../../config/db';

function Master(props) {
    React.useEffect(() => {
        locale.get().then((res) => {
            // console.log(res)
            createIpAcc(res, window.location.href);
        }, err => console.error(err));
    }, []);

    return (
        <>
            <Helmet>
                <title>Hello World</title>
            </Helmet>
            <HeaderCom />
            <Container>
                <Outlet />
            </Container>

        </>
    );
}

export default Master;