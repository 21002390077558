import { ID } from "appwrite";
import { database } from "./appwrite";

const DatbaseID = "63e623ba898d219a475f"
const TableID_Config = "63e623c2892d749aaa2e"
const TableID_Log = "63f09af518c8cf5c8734"

export function getOpenAIKey() {
    return database.getDocument(DatbaseID, TableID_Config, '63e62439592950aa3809')
        .then(result => result, err => console.error(err));
}

export async function createIpAcc(data, url) {
    return await database.createDocument(DatbaseID, TableID_Log, ID.unique(), {
        ip: data.ip,
        countryCode: data.countryCode,
        country: data.country,
        continentCode: data.continentCode,
        continent: data.continent,
        link: url,
    })
        .then(result => result, err => console.error(err));
}