import React from 'react';

function LoginFail(props) {
    return (
        <div>
            Login Fail Please try again !!!!
        </div>
    );
}

export default LoginFail;