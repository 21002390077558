import React from 'react';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';

import { account } from '../../config/appwrite';
import authStore from '../../store';

function MasterAuth(props) {
    const navigate = useNavigate();

    React.useEffect(() => {
        account.get().then((res) => {
            authStore.setHasLogin(res);
        }, (error) => {
            // console.error(error);
            navigate("/login");
        });
    }, [navigate]);

    return (
        <Outlet />
    );
}

export default observer(MasterAuth);