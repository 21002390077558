import { makeObservable, observable, computed, action } from "mobx"

class AuthStore {
    auth = false;

    constructor() {
        makeObservable(this, {
            auth: observable,
            isAuth: computed,
            setHasLogin: action,
        })
    }

    get isAuth() {
        return (this.auth) ? this.auth : false
    }

    setHasLogin = (auth) => this.auth = auth
}

const authStore = new AuthStore()

export default authStore;